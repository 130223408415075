<template>
  <div class="selectPurchaseScene">
    <div class="facilityBox" v-show="!flag.riskVerifying">
      <!-- 未点击提交申请 -->
      <div v-if="!isSubmitApply">
        <div style="display: flex; justify-content: space-between">
          <el-form
            class="queryForm"
            inline
            :model="manageForm"
            ref="manageForm"
          >
            <el-form-item label="待开票申请编号" prop="batchID">
              <el-input
                class="fromInp"
                v-model="manageForm.batchID"
                placeholder="请输入待开票申请编号"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="业务类型" prop="businessType">
              <el-select
                v-model="manageForm.businessType"
                placeholder="请选择业务类型"
                @change="businessChange"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="场景类型" prop="scenarioType">
              <el-select
                v-model="manageForm.scenarioType"
                placeholder="请选择场景类型"
                @change="sceneTypeChange"
              >
                <el-option
                  v-for="item in sceneList"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票类型" prop="invoiceType">
              <el-select
                v-model="manageForm.invoiceType"
                placeholder="请选择发票类型"
                @change="search"
              >
                <el-option
                  v-for="item in invoiceList"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="16px">
              <el-button type="primary" @click="search()" icon="el-icon-search"
                >搜索
              </el-button>
              <el-button
                type="primary"
                @click="clearData()"
                icon="el-icon-delete"
                >清空
              </el-button>
            </el-form-item>
          </el-form>
          <el-tooltip placement="top">
            <div slot="content">
              1.上传运单表格仅限Excel（Office2010及以上版本，WPS2021年及以后版本）<br />2.点击“下载运单模板”按钮，获得Excel模板，模板表格中第2页有案例可参考，模板中红色字段为必填项
            </div>
            <div style="margin: 10px 20px 0px; color: #0f5fff">
              <i class="el-icon-question"></i>
              <span>帮助</span>
            </div>
          </el-tooltip>
        </div>
        <div style="display: flex; align-items: center; margin: -10px 0px 8px">
          <el-button
            type="primary"
            size="medium"
            @click="mergeImport"
            icon="el-icon-connection"
            :disabled="!multipleSelection.length"
            >合并运单</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="downTemplate(2)"
            icon="el-icon-download"
          >
            下载运单模板
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="$refs.waybill.click()"
            icon="el-icon-upload2"
          >
            上传运单表格
          </el-button>

          <el-button
            type="primary"
            size="mini"
            icon="el-icon-download"
            @click="downTemplate(1)"
            >风控标准说明</el-button
          >
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-upload"
            v-if="flag.importTraceAuth"
            @click="$refs.importTrace.click()"
            >导入轨迹
          </el-button>
          <span style="margin-left: 24px"
            >注：单次最大可上传<span style="color: #ff2323">{{ maxNum }}</span
            >条运单</span
          >
          <input
            type="file"
            style="display: none"
            @change="handleUploadWaybill"
            ref="waybill"
          />
          <input
            type="file"
            style="display: none"
            @change="importTrace"
            ref="importTrace"
          />
        </div>
        <el-table
          :data="tableData"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          v-loading="flag.importListLoading"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          key="tableData"
        >
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="50"
            fixed="left"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="Remark"
            label="备注"
            show-overflow-tooltip
            align="center"
            width="200"
          >
            <template slot-scope="scope" width="200">
              <div
                :style="{ color: scope.row.Remark ? '#66b1ff' : '#909399' }"
                style="
                  cursor: pointer;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                "
                @click="openUpdateReamrk(scope.row)"
              >
                {{ scope.row.Remark ? scope.row.Remark : '暂无备注' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="BatchID"
            label="待开票申请编号"
            show-overflow-tooltip
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="BillCount"
            label="总运单数量"
            show-overflow-tooltip
            align="center"
            width="130"
          >
            <template slot-scope="scope">
              <div style="cursor: pointer; display: inline-block">
                <el-tag @click="openAllBill(scope.row, '')">{{
                  scope.row.BillCount
                }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="WaitCount"
            label="已风控运单数量"
            width="130"
          >
            <template slot-scope="scope">
              <div style="cursor: pointer; display: inline-block">
                <el-tag
                  type="success"
                  @click="openAllBill(scope.row, 'WaitStart')"
                  >{{ scope.row.WaitCount }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="FailCount"
            label="待风控运单数量"
            width="130"
          >
            <template slot-scope="scope">
              <div style="cursor: pointer; display: inline-block">
                <el-tag
                  type="danger"
                  @click="openAllBill(scope.row, 'RiskFail')"
                  >{{ scope.row.FailCount }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="TotalPrice"
            label="总运费"
            show-overflow-tooltip
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.TotalPrice | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="CorrectPrice"
            label="正确运单运费"
            show-overflow-tooltip
            align="center"
            width="200"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.CorrectPrice | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ImportTime"
            label="上传时间"
            show-overflow-tooltip
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="350"
            show-overflow-tooltip
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="submitRiskVerify(scope.row, 'all')"
                >提交风控</el-button
              >
              <el-button type="text" @click="submitApply(scope.row)"
                >提交申请</el-button
              >
              <el-button
                type="text"
                @click="openVoucherDialog(scope.row, 'ImportBatchID')"
                >上传凭证</el-button
              >
              <el-button
                type="text"
                style="color: #ff2323"
                @click="delImport(scope.row.BatchID)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 10px 0 0 0">
          <!-- 分页 -->
          <el-pagination
            class="pagination"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.page"
            :page-size="pagination.pagesize"
            :page-sizes="[10, 30, 50, 100]"
            @size-change="sizeChange"
            layout="total,sizes, prev, pager, next, jumper"
            :total="pagination.total"
          >
          </el-pagination>
        </div>
        <!-- 修改备注dialog -->
        <el-dialog
          width="600px"
          title="修改备注"
          :visible.sync="flag.updateRemarkDialog"
          append-to-body
          :close-on-click-modal="false"
        >
          <el-input
            type="textarea"
            autosize
            placeholder="请输入备注"
            maxlength="50"
            show-word-limit
            v-if="JSON.stringify() != '{}'"
            v-model="currentItem.Remark"
          ></el-input>
          <div style="text-align: center; margin-top: 16px">
            <el-button
              style="margin-right: 16px"
              type="primary"
              size="mini"
              @click="updateRemark"
              >确认
            </el-button>
            <el-button
              type="info"
              size="mini"
              @click="flag.updateRemarkDialog = false"
              >取消</el-button
            >
          </div>
        </el-dialog>
        <!-- 运单列表 -->
        <el-dialog
          width="1500px"
          :title="
            billStatus == ''
              ? '全部运单'
              : billStatus == 'RiskFail'
              ? '错误运单'
              : '正确运单'
          "
          :visible.sync="flag.allbillDialog"
          append-to-body
          :close-on-click-modal="false"
        >
          <div style="display: flex; align-items: center; margin-bottom: 8px">
            <el-form
              class="queryForm"
              inline
              :model="billManageForm"
              ref="billManageForm"
            >
              <el-form-item label="承运人手机号" prop="shipownerPhone">
                <el-input
                  class="fromInp"
                  v-model="billManageForm.shipownerPhone"
                  placeholder="请输入承运人手机号"
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item label="船舶名称" prop="shipName">
                <el-input
                  class="fromInp"
                  v-model="billManageForm.shipName"
                  placeholder="请输入船舶名称"
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item label="客户单号" prop="thirdBillID">
                <el-input
                  class="fromInp"
                  v-model="billManageForm.thirdBillID"
                  placeholder="请输入客户单号"
                  clearable
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="16px">
                <el-button
                  type="primary"
                  @click="openAllBill(currentItem, billStatus, 'search')"
                  >搜索
                </el-button>
                <el-button type="primary" @click="clearAllBill"
                  >清空
                </el-button>
                <el-button type="primary" @click="downloadBillList"
                  >下载表格
                </el-button>
                <el-button type="danger" @click="deleteAllWaybill"
                  >删除勾选运单</el-button
                >
                <el-button
                  type="primary"
                  @click="getEditList"
                  v-if="billStatus == 'RiskFail'"
                  >在线编辑</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <ux-grid
            :data="allBillList"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-loading="flag.allbillLoading"
            :key="billStatus"
            style="width: 100%"
            height="400"
            :header-cell-class-name="cellClass"
            @select="waybillSelectedChange"
            @select-all="waybillSelectedChange"
            show-overflow
          >
            <ux-table-column
              type="checkbox"
              align="center"
              width="55"
              fixed="left"
              show-overflow
            ></ux-table-column>
            <ux-table-column
              type="index"
              title="序号"
              width="50"
              fixed="left"
              show-overflow
              align="center"
            >
            </ux-table-column>
            <ux-table-column
              align="center"
              field="RiskRemark"
              title="错误信息"
              width="200"
              fixed="left"
              v-if="billStatus == 'RiskFail'"
            ></ux-table-column>
            <ux-table-column
              field="BBID"
              title="运单号"
              show-overflow
              align="center"
              width="200"
            >
            </ux-table-column>
            <ux-table-column
              field="TaskTypeName"
              title="场景类型"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="Shipper"
              title="托运人"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="ShipownerName"
              title="承运人"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="ShipownerIDCard"
              title="承运人身份证号"
              show-overflow
              align="center"
              width="180"
            >
            </ux-table-column>
            <ux-table-column
              field="ShipownerPhone"
              title="承运人手机号"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="PayeeName"
              title="代收人"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="PayeeIDCard"
              title="代收人身份证号"
              show-overflow
              align="center"
              width="180"
            >
            </ux-table-column>
            <ux-table-column
              field="PayeePhone"
              title="代收人手机号"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="ShipName"
              title="船舶名称"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="GoodsName"
              title="货物名称"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>

            <ux-table-column
              field="GoodsUnitName"
              title="货物单位"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="OriginAddress"
              title="起始地"
              show-overflow
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <div @click="openMap(scope.row)">
                  <el-link type="primary">{{
                    scope.row.OriginAddress
                  }}</el-link>
                </div>
              </template>
            </ux-table-column>
            <ux-table-column
              field="ArriveAddress"
              title="到达地"
              show-overflow
              align="center"
              width="180"
            >
              <template slot-scope="scope">
                <div @click="openMap(scope.row)">
                  <el-link type="primary">{{
                    scope.row.ArriveAddress
                  }}</el-link>
                </div>
              </template>
            </ux-table-column>
            <ux-table-column
              field="OriginTime"
              title="起始时间"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="ArriveTime"
              title="到达时间"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="Counts"
              title="货物数量"
              show-overflow
              align="center"
              width="150"
            >
            </ux-table-column>
            <ux-table-column
              field="Price"
              title="运输单价"
              show-overflow
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <span style="color: #ff8b17">{{
                  scope.row.Price | formatMoney
                }}</span>
              </template>
            </ux-table-column>
            <ux-table-column
              field="CarriageFee"
              title="运费"
              show-overflow
              align="center"
              width="150"
            >
              <template slot-scope="scope">
                <span style="color: #ff8b17">{{
                  scope.row.CarriageFee | formatMoney
                }}</span>
              </template>
            </ux-table-column>
            <ux-table-column
              field="TrustFee"
              title="委托代开运费"
              show-overflow
              align="center"
              width="150"
              v-if="manageForm.businessType == 'DK'"
            >
              <template slot-scope="scope">
                <span style="color: #ff8b17">{{
                  scope.row.TrustFee | formatMoney
                }}</span>
              </template>
            </ux-table-column>
            <ux-table-column
              field="ThirdBillID"
              title="客户单号"
              show-overflow
              align="center"
              width="180"
            >
            </ux-table-column>
            <ux-table-column
              fixed="right"
              align="center"
              field=""
              title="操作"
              width="130"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openVoucherDialog(scope.row, 'WaybillID')"
                  >凭证</el-button
                >
              </template>
            </ux-table-column>
          </ux-grid>

          <div style="margin: 10px 0 0 0">
            <!-- 分页 -->
            <el-pagination
              class="pagination"
              background
              @current-change="handleCurrentChangeAll"
              :current-page.sync="paginationAll.page"
              :page-size="paginationAll.pagesize"
              :total="paginationAll.total"
              :page-sizes="[10, 20, 30, 40, 50, 100, 500, 1000]"
              @size-change="paginationAllSizeChange"
              layout="total,sizes, prev, pager, next, jumper"
            >
            </el-pagination>
          </div>
        </el-dialog>
        <!-- 上传运单错误信息dialog -->
        <el-dialog
          :visible.sync="flag.uploadErrorDialog"
          title="错误说明"
          center
          append-to-body
        >
          <div style="height: 60vh; overflow-y: scroll">
            <el-table
              :data="uploadErrorList"
              :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              key="uploadErrorList"
            >
              <el-table-column
                align="center"
                prop="LineNumber"
                label="错误行"
                width="200"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="Msg"
                label="错误信息"
                show-overflow-tooltip
              >
              </el-table-column>
            </el-table>
          </div>
        </el-dialog>
      </div>
      <!-- 点击提交申请 -->
      <div v-else>
        <!-- 合计 -->
        <div class="settleBox">
          <el-row>
            <el-col :span="5"
              ><strong>发票抬头：</strong
              >{{ settlementInfo.InvoiceTitleName }}</el-col
            >
            <el-col :span="5"
              ><strong>纳税人识别号：</strong
              >{{ settlementInfo.InvoiceTitleCreditCode }}</el-col
            >
            <el-col :span="5"
              ><strong>开户银行：</strong
              >{{ settlementInfo.InvoiceTitleBankerName }}</el-col
            >
            <el-col :span="9"
              ><strong>开户银行账号：</strong
              >{{ settlementInfo.InvoiceTitleBankerNumber }}</el-col
            >
          </el-row>
          <el-row style="margin: 4px 0px">
            <el-col :span="5"
              ><strong>运费(元)：</strong
              >{{ goodsInfo.BillTotalPrice | formatMoney }}</el-col
            >
            <el-col :span="5"
              ><strong>服务费/税费(元)：</strong
              >{{ goodsInfo.Servicefee | formatMoney }}</el-col
            >
            <el-col :span="5"
              ><strong>运单总金额(元)：</strong
              >{{ goodsInfo.InvoiceTotalPrice | formatMoney }}</el-col
            >
            <el-col :span="9"
              ><strong>运单条数：</strong>{{ goodsInfo.BillCount }}</el-col
            >
          </el-row>
          <el-row>
            <el-col :span="5"
              ><strong>联系电话：</strong
              >{{ settlementInfo.InvoiceTitlePhone }}</el-col
            >
            <el-col :span="9"
              ><strong>单位地址：</strong
              >{{ settlementInfo.InvoiceTitleAddress }}</el-col
            >
          </el-row>
        </div>
        <el-divider></el-divider>
        <div style="display: flex; align-items: center; margin-bottom: 8px">
          <div>
            <span>已签约服务商：</span>
            <el-select
              v-model="supplierUserID"
              clearable
              placeholder="请选择"
              @change="searchWaitBillList"
            >
              <el-option
                v-for="(item, index) in supplierList"
                :key="index"
                :label="
                  !item.EnableRemark
                    ? item.EnterpriseFullName
                    : item.EnterpriseFullName + ':' + item.EnableRemark
                "
                :value="item.UserID"
                :disabled="!item.IsEnable"
              >
              </el-option>
            </el-select>
          </div>
          <div style="margin: 0px 108px">
            <span>专票普票：</span>
            <!-- <el-select v-model="invoiceKindValue" clearable placeholder="请选择">
                            <el-option v-for="(item, index) in ticketTypeList" :key="index" :label="item.Name"
                                :value="item.Code">
                            </el-option>
                        </el-select> -->
            <el-input
              placeholder="请选择专票普票"
              v-model="invoiceKindValue"
              clearable
              @clear="cancleSelectKind"
              @focus="flag.invoiceKindDialog = true"
              style="width: 380px"
            >
              <i slot="suffix" class="el-input__icon el-icon-arrow-down"></i>
            </el-input>
          </div>
          <div>
            <span>支付方式：</span>
            <el-select
              v-model="paymentCode"
              clearable
              placeholder="请选择"
              @change="searchWaitBillList"
            >
              <el-option
                v-for="(item, index) in paymentTypeList"
                :key="index"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-table
          :data="waitBillList"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          v-loading="flag.waitbillLoading"
          key="waitBillList"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
            fixed="left"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="BBID"
            label="运单号"
            show-overflow-tooltip
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="InvoiceTypeName"
            label="发票类型"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="TaskTypeName"
            label="业务类型"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="TaskSceneTypeName"
            label="场景类型"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="Rate"
            label="平台费率"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="Shipper"
            label="托运人"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="ShipownerName"
            label="承运人"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="ShipownerIDCard"
            label="承运人身份证号"
            show-overflow-tooltip
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="ShipownerPhone"
            label="承运人手机号"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="PayeeName"
            label="代收人"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="PayeeIDCard"
            label="代收人身份证号"
            show-overflow-tooltip
            align="center"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="PayeePhone"
            label="代收人手机号"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="ShipName"
            label="船舶名称"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="GoodsName"
            label="货物名称"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="GoodsUnitName"
            label="货物单位"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="OriginAddress"
            label="起始地"
            show-overflow-tooltip
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="ArriveAddress"
            label="到达地"
            show-overflow-tooltip
            align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="OriginTime"
            label="起始时间"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="ArriveTime"
            label="到达时间"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="Counts"
            label="货物数量"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="Price"
            label="运输单价"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.Price | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="CarriageFee"
            label="运费"
            show-overflow-tooltip
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.CarriageFee | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Servicefee"
            label="服务费/税费"
            show-overflow-tooltip
            align="center"
            width="150"
            v-if="!currentItem.TaskTypeID.includes('DK')"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.Servicefee | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="InvoiceTotalPrice"
            label="运单总金额"
            show-overflow-tooltip
            align="center"
            width="150"
            v-if="!currentItem.TaskTypeID.includes('DK')"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.InvoiceTotalPrice | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="TrustFee"
            label="委托代开运费"
            show-overflow-tooltip
            align="center"
            width="150"
            v-if="currentItem.TaskTypeID.includes('DK')"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.TrustFee | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Servicefee"
            label="委托代开服务费/税费"
            show-overflow-tooltip
            align="center"
            width="150"
            v-if="currentItem.TaskTypeID.includes('DK')"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.Servicefee | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="InvoiceTotalPrice"
            label="委托代开运单总金额"
            show-overflow-tooltip
            align="center"
            width="150"
            v-if="currentItem.TaskTypeID.includes('DK')"
          >
            <template slot-scope="scope">
              <span style="color: #ff8b17">{{
                scope.row.InvoiceTotalPrice | formatMoney
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="ThirdBillID"
            label="客户单号"
            show-overflow-tooltip
            align="center"
            width="150"
          >
          </el-table-column>
        </el-table>
        <div style="margin: 10px 0 0 0">
          <!-- 分页 -->
          <el-pagination
            class="pagination"
            background
            @current-change="handleCurrentChangeWait"
            :current-page.sync="paginationWait.page"
            :page-size="paginationWait.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="paginationWait.total"
          >
          </el-pagination>
        </div>
        <div style="text-align: center">
          <el-button
            type="info"
            plain
            size="medium"
            icon="el-icon-back"
            @click="isSubmitApply = false"
            >上一步
          </el-button>
          <el-button
            type="primary"
            plain
            size="medium"
            @click="openVoucherDialog(currentItem, 'ImportBatchID')"
            icon="el-icon-upload2"
            :disabled="!waitBillList.length"
          >
            上传凭证
          </el-button>
          <el-button
            type="primary"
            size="medium"
            @click="getPayment"
            icon="el-icon-wallet"
            :disabled="
              !paymentCode ||
              !invoiceKindValue ||
              !supplierUserID ||
              !waitBillList.length
            "
            :loading="flag.getPayLoading"
          >
            立即付款
          </el-button>
        </div>
      </div>
      <!-- 发票类型选择 -->
      <el-dialog
        width="550px"
        title="选择开票类型"
        :visible.sync="flag.invoiceKindDialog"
        append-to-body
        class="invoiceKindDialog"
      >
        <el-divider></el-divider>
        <div
          style="
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 8px;
          "
        >
          运费发票
        </div>
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <el-radio
            v-model="invoiceKindVal1"
            v-for="(item, index) in invoiceKindLists.list"
            :key="index"
            :label="item"
            :disabled="item.IsDisable"
            style="margin-bottom: 10px"
            >{{ item.Name }}</el-radio
          >
        </div>
        <div
          style="
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            margin-top: 24px;
            margin-bottom: 8px;
          "
        >
          技术服务费发票
        </div>
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <el-radio
            v-model="invoiceKindVal2"
            v-for="(item, index) in invoiceKindLists.list1"
            :key="index"
            :label="item"
            :disabled="item.IsDisable"
            style="margin-bottom: 10px"
            >{{ item.Name }}</el-radio
          >
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="flag.invoiceKindDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitSelectKind">保 存</el-button>
        </span>
      </el-dialog>
      <!-- 上传凭证dialog -->
      <el-dialog
        width="1200px"
        title="凭证"
        :visible.sync="flag.voucherDialog"
        append-to-body
        v-if="flag.voucherDialog"
        :close-on-click-modal="false"
      >
        <EvidenceInfo
          :businessID="
            voucherType == 'ImportBatchID'
              ? currentItem.BatchID
              : currentItem.BBID
          "
          :isShowOfflineVoucher="flag.showOfflineVoucher"
        >
        </EvidenceInfo>
      </el-dialog>
      <!-- 立即付款dialog -->
      <el-dialog
        width="780px"
        title="付款信息"
        :visible.sync="flag.getPayDialog"
        append-to-body
        v-if="flag.getPayDialog"
        :close-on-click-modal="false"
      >
        <div style="margin-bottom: 20px">
          <p style="color: #333">
            货主点击
            <span style="color: #0f5fff">【确认付款】</span
            >，将从您的虚拟账户扣除运单待支付总金额。付款成功后，等待服务商开票。
          </p>
        </div>
        <div class="pay-info">
          <div class="info-con">
            <div class="title">服务商单位名称：</div>
            <div class="con">{{ paymentInfo.supplierUserName }}</div>
          </div>
          <div class="info-con">
            <div class="title">账户余额：</div>
            <div class="con1">{{ accountInfo.Balance | formatMoney }}</div>
          </div>
          <div class="info-con">
            <div class="title">发票类型：</div>
            <div class="con">{{ waitBillList[0].InvoiceTypeName }}</div>
          </div>
          <div class="info-con">
            <div class="title">可交易金额：</div>
            <div class="con1">{{ accountInfo.DealAmount | formatMoney }}</div>
          </div>
          <div class="info-con">
            <div class="title">发票费率：</div>
            <div class="con" style="color: #ff8b17; font-weight: 600">
              {{ settlementInfo.InvoiceRate }}
            </div>
          </div>
          <div class="info-con">
            <div class="title">在途金额：</div>
            <div class="con1">{{ accountInfo.FrozeAmount | formatMoney }}</div>
          </div>
          <div class="info-con">
            <div class="title">支付方式：</div>
            <div class="con" style="color: #ff8b17; font-weight: 600">
              {{ paymentInfo.paymentCodeName }}
            </div>
          </div>
          <div class="info-con">
            <div class="title">待支付总金额：</div>
            <div class="con1">
              {{
                (paymentCode == '3' || paymentCode == '4'
                  ? goodsInfo.Servicefee
                  : goodsInfo.InvoiceTotalPrice) | formatMoney
              }}
            </div>
          </div>
          <div class="info-con">
            <div class="title">专票普票：</div>
            <div class="con">{{ paymentInfo.invoiceKindName }}</div>

            <span
              v-if="paymentInfo.paymentCodeName.includes('线下')"
              style="
                color: red;
                margin-left: 14px;
                font-size: 18px;
                font-weight: bold;
              "
              >技术服务费回单凭证不用上传
            </span>
          </div>
        </div>
        <el-divider></el-divider>
        <div
          style="
            text-align: center;
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
          "
        >
          <el-button
            type="primary"
            @click="openVoucherDialog(currentItem, 'ImportBatchID')"
            >线下付款凭证</el-button
          >
          <div>
            <el-button type="info" plain @click="flag.getPayDialog = false"
              >取消</el-button
            >
            <el-button
              :disabled="flag.isComfirmPay"
              type="primary"
              @click="comfirmPays"
              :loading="flag.getPayLoading"
              >确认付款</el-button
            >
          </div>
        </div>
      </el-dialog>
      <!-- 在线编辑 -->
      <el-dialog
        width="75%"
        :visible.sync="flag.editWaybillDialog"
        append-to-body
        :close-on-click-modal="false"
        v-if="flag.editWaybillDialog"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;
          "
        >
          <strong style="font-size: 16px">在线编辑中</strong>
        </div>
        <div style="display: flex; align-items: center; margin-bottom: 8px">
          <el-form
            class="queryForm"
            inline
            :model="editBillManageForm"
            ref="editBillManageForm"
          >
            <el-form-item label="承运人手机号" prop="shipownerPhone">
              <el-input
                class="fromInp"
                v-model="editBillManageForm.shipownerPhone"
                placeholder="请输入承运人手机号"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="船舶名称" prop="shipName">
              <el-input
                class="fromInp"
                v-model="editBillManageForm.shipName"
                placeholder="请输入船舶名称"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label="客户单号" prop="thirdBillID">
              <el-input
                class="fromInp"
                v-model="editBillManageForm.thirdBillID"
                placeholder="请输入客户单号"
                clearable
              >
              </el-input>
            </el-form-item>
            <el-form-item label-width="16px">
              <el-button
                type="primary"
                @click="getEditList('search')"
                icon="el-icon-search"
              >
                搜索</el-button
              >
              <el-button type="primary" @click="clearEditAllBill"
                >清空
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <vxe-table
          ref="editWaybillRef"
          v-loading="flag.dialogLoading"
          keep-source
          @checkbox-change="currentEditWaybillSelectedChange"
          @checkbox-all="currentEditWaybillSelectedChange"
          :header-cell-class-name="headerCellClassName"
          :cell-class-name="cellClassName"
          border
          resizable
          show-overflow
          :data="currentEditWaybillList"
          :edit-config="{ trigger: 'click', mode: 'cell' }"
        >
          <vxe-table-column
            align="center"
            field="RiskRemark"
            title="错误信息"
            width="200"
            fixed="left"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="BBID"
            title="运单号"
            width="200"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="TaskTypeName"
            title="场景类型"
            width="200"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="Shipper"
            title="托运人"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="ShipownerName"
            title="承运人"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="ShipownerPhone"
            title="承运人手机号码"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="ShipownerIDCard"
            title="承运人身份证号码"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          >
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="PayeeName"
            title="代收人姓名"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="PayeePhone"
            title="代收人手机号码"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="PayeeIDCard"
            title="收代收人身份证号码"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          >
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="ShipName"
            title="船舶名称"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          >
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="GoodsName"
            title="货物名称"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="GoodsUnit"
            title="货物单位"
            :edit-render="{ name: 'input', autoselect: true }"
            width="200"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="OriginAddress"
            title="起始地"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #default="{ row }">
              <span>{{ row.OriginAddress }}</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="ArriveAddress"
            title="到达地"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #default="{ row }">
              <span>{{ row.ArriveAddress }}</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="OriginTime"
            title="起始时间"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="ArriveTime"
            title="到达时间"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="Counts"
            title="货物数量"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="Price"
            title="运输单价"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
          <vxe-table-column
            align="center"
            field="CarriageFee"
            title="运费"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          >
            <template #default="{ row }">
              <span>{{ row.CarriageFee | formatMoney }}</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="TrustFee"
            title="委托代开运费"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
            v-if="manageForm.businessType == 'DK'"
          >
            <template #default="{ row }">
              <span>{{ row.TrustFee | formatMoney }}</span>
            </template>
          </vxe-table-column>
          <vxe-table-column
            align="center"
            field="ThirdBillID"
            title="客户单号"
            width="200"
            :edit-render="{ name: 'input', autoselect: true }"
          ></vxe-table-column>
        </vxe-table>
        <div style="margin: 10px 0 0 0">
          <!-- 分页 -->
          <el-pagination
            class="pagination"
            background
            @current-change="handleCurrentChangeEdit"
            :current-page.sync="paginationEdit.page"
            :page-size="paginationEdit.pagesize"
            :total="paginationEdit.total"
            layout="total,prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div style="margin-top: 16px">
            <el-button
              style="margin: 0 0 0 16px"
              type="primary"
              @click="saveWaybill"
              >保存编辑</el-button
            >
            <el-button
              style="margin: 0 0 0 16px"
              type="primary"
              @click="revertWaybill"
              >撤销编辑</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 风控校验 -->
    <div class="riskVerify" v-if="flag.riskVerifying">
      <el-progress
        :width="400"
        type="circle"
        :stroke-width="20"
        :percentage="flag.riskProgress"
      ></el-progress>
      <p class="desc">风控校验中，请稍后...</p>
    </div>

    <el-dialog
      width="800px"
      :visible.sync="traceDialog"
      append-to-body
      class="deep_dialog"
      :close-on-click-modal="false"
    >
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="traceDialog" ref="map" :list="mapList">
      </TXmap>
    </el-dialog>
  </div>
</template>
<script>
import { GetImportTraceAuth } from '@/api/waybill/waybillManage/index'
import {
  ImportList,
  DelImport,
  MergeImport,
  DownTemplate,
  UpLoadExcel,
  UpdateImportRemark,
  BillList,
  ImportTrace,
  DownloadBillList,
} from '@/api/purchaseManage/selectPurchaseScene'
import {
  GetInvoiceTitleInfo,
  GetBillStatistics,
  GetSignSupplier,
  GetTicketType,
  GetPaymentMethod,
  PayAndCreateStatement,
  GetBillDetail,
  GetBTaskType,
  RiskShip,
  WaitBillList,
  UpdateBillDetail,
  DelBillDetail,
  getMapPath,
} from '@/api/purchaseManage/invoiceFlow'

import TXmap from '@/components/commonCmpt/TXmap'
import { getYMAccount } from '@/api/finance/bank'
import { GetSysValueAsync } from '@/api/common/common'
import EvidenceInfo from '@/components/businessCmpt/evidenceInfo'
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      mapList: [],
      traceDialog: false,
      batchID: '', // 重选服务商带来的值
      isSubmitApply: false, // 是否提交申请
      manageForm: {
        batchID: '', //待开票申请编号
        businessType: '', // 业务类型
        scenarioType: '', // 场景类型
        invoiceType: '', // 发票类型
      },
      flag: {
        importListLoading: false, // 获取列表loading
        updateRemarkDialog: false, // 备注弹窗
        allbillDialog: false, //所有运单弹窗
        allbillLoading: false, //所有运单loading
        waitbillLoading: false, // 待付款列表
        voucherDialog: false, // 上传凭证弹窗
        uploadErrorDialog: false, // 上传运单错误弹窗
        getPayLoading: false, // 立即付款按钮
        getPayDialog: false, // 立即付款弹窗
        showOfflineVoucher: false, // 是否是线下付款
        isComfirmPay: false, // 能否付款
        riskVerifying: false, // 风控弹窗
        errorWaybillDialog: false, //错误运单详情dialog
        riskProgress: 0, //风控进度
        errorWaybillDialog: false,
        editWaybillDialog: false, // 在线编辑弹窗
        dialogLoading: false,
        invoiceKindDialog: false,
        importTraceAuth: false, // 导入轨迹权限
      },
      uploadErrorList: [], // 上传运单错误列表
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      paginationAll: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      paginationWait: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      billManageForm: {
        shipownerPhone: '', // 承运人手机号
        shipName: '', // 船舶名称
        thirdBillID: '', // 客户单号
      },
      editBillManageForm: {
        shipownerPhone: '', // 承运人手机号
        shipName: '', // 船舶名称
        thirdBillID: '', // 客户单号
      },
      // 表单数据
      tableData: [], //表格数据
      multipleSelection: [], // 选中项
      currentItem: {}, // 当前项
      allBillList: [], // 所有运单列表
      goodsName: '', //运单名称
      settlementInfo: {}, // 发票六要素
      supplierUserID: '', // 服务商id
      paymentCode: '', //支付方式
      invoiceKindValue: '', // 发票类型
      invoiceKindVal1: {},
      invoiceKindVal2: {},
      invoiceKindLists: {
        value: '',
        disabled: false,
        list: [],
        list1: [],
      }, //专票普票
      supplierList: [], //服务商列表
      ticketTypeList: [], //票种列表
      paymentTypeList: [], //支付方式列表
      waitBillList: [], // 所有运单列表
      goodsInfo: {}, // 运单统计
      paymentInfo: {
        supplierUserName: '', // 服务商单位名称
        invoiceKindName: '', // 专票普票类型
        paymentCodeName: '', // 支付方式
      }, // 付款信息
      accountInfo: {},
      businessList: [], // 业务类型列表
      sceneList: [], // 场景类型列表
      invoiceList: [], // 发票类型
      billStatus: '', // 运单状态  传空为所有运单 RiskFail为错误运单  WaitStart正确运单
      waybillSelectedList: [], // 当前勾选的运单
      editWaybillList: [], //在线编辑的运单
      paginationEdit: {
        page: 1, //当前所处的页码
        pagesize: 6, //每次请求的数量
        total: 0, //总条数
      },
      currentEditWaybillSelectedList: [], //当前勾选的编辑运单
      voucherType: '', // 上次凭证类型
      maxNum: 0, //单次最大上传数
    }
  },
  methods: {
    openMap(item) {
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let params = {
        bbid: item.BBID,
      }
      getMapPath(params)
        .then((res) => {
          console.log(res)
          let { data } = res
          this.mapList = data
          this.traceDialog = true
          console.log(this.mapList)
        })
        .finally(() => {
          loading.close()
        })
    },
    ...mapMutations(['setInvoiceTaskID']),
    // 获取业务场景类型
    async getBTaskType(level, code) {
      await GetBTaskType({
        level,
        code,
      }).then(async (res) => {
        if (level == 1) {
          this.businessList = res.data || []
          if (this.businessList.length && !this.manageForm.businessType) {
            this.manageForm.businessType = this.businessList[0].Code
            await this.getBTaskType(2, this.manageForm.businessType)
          }
        }
        if (level == 2) {
          this.sceneList = res.data || []
          if (this.sceneList.length && !this.manageForm.scenarioType) {
            this.manageForm.scenarioType = this.sceneList[0].Code
            await this.getBTaskType(3, this.manageForm.scenarioType)
          }
        }
        if (level == 3) {
          this.invoiceList = res.data || []
          if (this.invoiceList.length) {
            this.manageForm.invoiceType = this.manageForm.invoiceType
              ? this.manageForm.invoiceType
              : this.invoiceList[0].Code
          }
        }
        this.getImportList()
      })
    },
    // 选择业务类型
    businessChange() {
      if (this.manageForm.businessType)
        this.getBTaskType(2, this.manageForm.businessType)
      this.sceneList = []
      this.invoiceList = []
      this.manageForm.scenarioType = ''
      this.manageForm.invoiceType = ''
    },
    // 选择场景类型
    sceneTypeChange() {
      if (this.manageForm.scenarioType)
        this.getBTaskType(3, this.manageForm.scenarioType)
      this.invoiceList = []
      this.manageForm.invoiceType = ''
    },
    //打开上传凭证弹窗
    openVoucherDialog(item, type) {
      this.voucherType = type
      if (item != null) {
        this.currentItem = item
        if (item.BillCount == 0) {
          this.$message.warning('没有可提交申请的运单！')
          return
        }
      }
      this.flag.voucherDialog = true
    },
    // 获取批次列表
    getImportList() {
      this.flag.importListLoading = true
      ImportList({
        batchID: this.manageForm.batchID,
        taskTypeID: this.manageForm.invoiceType,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      })
        .then((res) => {
          this.tableData = res.data.DataList
          this.pagination.total = Number(res.data.TotalCount)
        })
        .finally(() => {
          this.flag.importListLoading = false
        })
    },
    // 搜索
    search() {
      this.pagination.page = 1
      this.tableData = []
      this.getImportList()
    },
    clearData() {
      this.$refs['manageForm'].resetFields()
      this.getBTaskType(1, '')
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.getImportList()
    },

    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e
      this.getImportList()
    },

    // 选中表格
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 删除批次
    delImport(batchID) {
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '删除中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          DelImport({
            batchID,
          })
            .then((res) => {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.getImportList()
            })
            .finally(() => {
              loading.close()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 合并批次
    mergeImport() {
      this.$confirm('确定合并批次吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '合并中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          MergeImport({
            batchIDList: this.multipleSelection.map((i) => {
              return i.BatchID
            }),
          })
            .then((res) => {
              this.$message({
                type: 'success',
                message: '合并成功!',
              })
              this.getImportList()
            })
            .finally(() => {
              loading.close()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    //下载模板
    downTemplate(type) {
      if (type == 1) {
        window.open(
          'https://res.gts56.com/fzkj/bsc/TempLate/2024%E6%B0%B4%E8%BF%90%E9%A3%8E%E6%8E%A7%E8%A7%84%E5%88%99.pdf',
          '_blank'
        )
      } else {
        if (!this.manageForm.invoiceType) {
          this.$message.warning('请选择业务场景类型！')
          return
        }
        DownTemplate({
          bTaskTypeCode: this.manageForm.invoiceType,
        }).then((res) => {
          window.open(res.data.ImportTemplateUrl)
        })
      }
    },

    // 导入轨迹
    importTrace(e) {
      const loading = this.$loading({
        lock: true,
        text: '轨迹导入中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      const file = e.srcElement.files[0] || e.target.files[0]
      let formData = new FormData()
      formData.append('file', file)
      ImportTrace(formData)
        .then((res) => {
          this.getImportList()
        })
        .finally(() => {
          this.$refs.importTrace.value = null
          loading.close()
        })
    },

    //上传运单
    handleUploadWaybill(e) {
      if (!this.manageForm.invoiceType) {
        this.$message.warning('请选择业务场景类型！')
        this.$refs.waybill.value = null
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      let formData = new FormData()
      formData.append('file', e.srcElement.files[0] || e.target.files[0])
      formData.append('TaskTypeID', this.manageForm.invoiceType)
      UpLoadExcel(formData)
        .then((res) => {
          if (res.data && res.data.length) {
            this.uploadErrorList = res.data
            this.flag.uploadErrorDialog = true
          }
          this.getImportList()
        })
        .finally(() => {
          this.$refs.waybill.value = null
          loading.close()
        })
    },
    //打开修改备注弹窗
    openUpdateReamrk(item) {
      this.currentItem = JSON.parse(JSON.stringify(item))
      this.flag.updateRemarkDialog = true
    },
    //修改备注
    updateRemark() {
      let params = {
        batchID: this.currentItem.BatchID,
        Remark: this.currentItem.Remark,
      }
      UpdateImportRemark(params)
        .then((res) => {
          this.getImportList()
        })
        .finally(() => {
          this.flag.updateRemarkDialog = false
        })
    },
    //提交风控校验
    submitRiskVerify(item, type) {
      this.currentItem = item
      if (type == 'all') {
        this.flag.errorWaybillDialog = false
        const loading = this.$loading({
          lock: true,
          text: '请求中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        //获取需要过风控的运单
        WaitBillList({
          BatchID: this.currentItem.BatchID,
          WaitBillList: true,
        })
          .then((res) => {
            if (res.data.length === 0) {
              this.$message.warning('暂无运单')
              return
            }
            this.riskVerify(res.data)
          })
          .finally(() => {
            loading.close()
          })
      } else {
        let arr = []
        this.waybillSelectedList.forEach((it) => {
          it.forEach((el) => {
            arr.push(el)
          })
        })
        if (arr.length == 0) {
          this.$message.warning('尚未勾选运单')
          return
        }
        this.$confirm('确认勾选提交风控吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.flag.errorWaybillDialog = false
            this.riskVerify(arr)
          })
          .catch()
      }
    },
    riskVerify(arr) {
      let that = this
      this.flag.riskVerifying = true
      this.flag.riskProgress = 0
      const maxNum = 1000
      let progress = 0 //已经风控校验了多少条
      let errorCount = 0 //有多少条错的

      const len = arr.length // 请求总数量
      let sendCount = 0 // 已发送的请求数量
      let finishCount = 0 // 已完成的请求数量
      return new Promise((resolve, reject) => {
        while (sendCount < maxNum && sendCount < len) {
          next()
        }
        function next() {
          let current = sendCount++ // 当前发送的请求数量，后加一 保存当前请求url的位置
          // 递归出口
          if (finishCount >= len) {
            // 如果所有请求完成，则解决掉 Promise，终止递归
            resolve()
            return
          }
          if (arr[current]) {
            let params = {
              bbid: arr[current].BBID,
            }
            RiskShip(params)
              .then((res) => {
                if (!res.data) {
                  errorCount = errorCount + 1
                }
              })
              .finally(() => {
                finishCount = finishCount + 1
                if (current < len - 1) {
                  // 如果请求没有发送完，继续发送请求
                  next()
                }
                progress = progress + 1
                that.flag.riskProgress = parseInt((progress / len) * 100)
                if (that.flag.riskProgress == 100) {
                  //风控校验完毕后需要刷新列表获取新数据
                  that.getImportList()
                  //延迟一秒关闭风控界面
                  let timer = setTimeout(() => {
                    that.flag.riskVerifying = false
                    // 一条错的都没有
                    if (errorCount === 0) {
                      that.$message.success('风控校验通过')
                    } else {
                      that.openAllBill(that.currentItem, 'RiskFail')
                      that.$message.warning(
                        '您有未通过风控的运单，请查看风控结果！'
                      )
                    }
                    clearTimeout(timer)
                  }, 1000)
                }
              })
          }
        }
      })
    },
    //查看运单
    openAllBill(item, billType, type) {
      this.billStatus = billType
      if (item.BillCount == 0) {
        this.$message.warning('暂无运单')
        return
      }
      if (type == 'search') {
        this.paginationAll.page = 1
      }
      this.allBillList = []
      this.currentItem = item
      this.flag.allbillDialog = true
      this.flag.allbillLoading = true
      let params = {
        batchID: this.currentItem.BatchID,
        pageIndex: this.paginationAll.page,
        pageSize: this.paginationAll.pagesize,
        status: this.billStatus,
        ...this.billManageForm,
        IsWaitBill: false,
      }
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      BillList(params)
        .then((res) => {
          this.allBillList = res.data.DataList
          this.paginationAll.total = Number(res.data.TotalCount)
        })
        .finally(() => {
          this.flag.allbillLoading = false
          loading.close()
        })
    },
    // 清空查询条件
    clearAllBill() {
      this.billManageForm = {
        shipownerPhone: '', // 承运人手机号
        shipName: '', // 船舶名称
        thirdBillID: '', // 客户单号
      }
      this.openAllBill(this.currentItem, this.billStatus, 'search')
    },
    waybillSelectedChange(e) {
      this.waybillSelectedList[this.paginationAll.page - 1] = e
    },
    //删除多条运单
    deleteAllWaybill() {
      this.$confirm('确认删除这些运单吗', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let arr = []
          this.waybillSelectedList.forEach((item) => {
            item.forEach((el) => {
              arr.push(el.BBID)
            })
          })
          if (arr.length == 0) {
            this.$message.warning('尚未勾选运单')
            return
          }
          let params = {
            bbidList: arr,
          }
          const loading = this.$loading({
            lock: true,
            text: '删除中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          DelBillDetail(params)
            .then((res) => {
              this.openAllBill(this.currentItem, this.billStatus)
              this.getImportList()
            })
            .finally(() => {
              loading.close()
            })
        })
        .catch()
    },
    //分页控件页码change事件回调
    handleCurrentChangeAll(e) {
      this.paginationAll.page = e
      this.openAllBill(this.currentItem, this.billStatus)
    },
    paginationAllSizeChange(e) {
      this.paginationAll.pagesize = e
      this.openAllBill(this.currentItem, this.billStatus)
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'DisableSelection'
      }
    },
    // 点击提交申请
    submitApply(item) {
      if (item.WaitCount == 0) {
        this.$message.warning('没有可提交申请的运单！')
        return
      }
      this.currentItem = item
      this.supplierUserID = ''
      this.paymentCode = ''
      this.invoiceKindValue = ''
      this.invoiceKindVal1 = {}
      this.invoiceKindVal2 = {}
      this.waitBillList = []
      this.submitApplyRequest()
    },
    // 提交申请后的数据请求
    submitApplyRequest() {
      this.getInvoiceTitleInfo()
      this.getSignSupplier()
      this.getTicketType()
      this.GetPaymentMethod()
      this.getBillList()
      this.isSubmitApply = true
    },
    // 获取发票六要素等开票信息
    async getInvoiceTitleInfo() {
      await GetInvoiceTitleInfo({
        bTaskTypeCode: this.currentItem.TaskTypeID,
      }).then((res) => {
        this.settlementInfo = res.data || {}
      })
    },
    // 获取运单统计
    async getBillStatistics() {
      await GetBillStatistics({
        bTaskTypeCode: this.currentItem.TaskTypeID,
        batchID: this.currentItem.BatchID,
        supplierUserID: this.supplierUserID,
        paymentCode: this.paymentCode,
      }).then((res) => {
        this.goodsInfo = res.data || {}
      })
    },
    // 获取签约服务商
    getSignSupplier() {
      GetSignSupplier({
        bTaskTypeCode: this.currentItem.TaskTypeID,
      }).then((res) => {
        this.supplierList = res.data || []
      })
    },
    // 获取票种
    getTicketType() {
      GetTicketType({ bTaskTypeCode: this.currentItem.TaskTypeID }).then(
        (res) => {
          this.invoiceKindLists.list = res.data.Carriage
          this.invoiceKindLists.list1 = res.data.CarriageTec
        }
      )
    },
    // 取消选择专普票
    cancleSelectKind() {
      this.flag.invoiceKindDialog = false
      this.invoiceKindValue = ''
      this.invoiceKindVal1 = {}
      this.invoiceKindVal2 = {}
    },
    // 保存选择专普票
    submitSelectKind() {
      if (!this.invoiceKindVal1.Name) {
        this.$message.warning('请选择运费发票！')
        return
      }
      if (!this.invoiceKindVal2.Name) {
        this.$message.warning('请选择技术服务费发票！')
        return
      }
      this.invoiceKindValue = `运费-${this.invoiceKindVal1.Name} 技术服务费-${this.invoiceKindVal2.Name}`
      this.flag.invoiceKindDialog = false
    },
    // 获取支付方式
    GetPaymentMethod() {
      GetPaymentMethod({
        bTaskTypeCode: this.currentItem.TaskTypeID,
      }).then((res) => {
        this.paymentTypeList = res.data || []
      })
    },
    // 获取业务单列表--待付款
    getBillList() {
      this.flag.waitbillLoading = true
      let params = {
        pageIndex: this.paginationWait.page,
        pageSize: this.paginationWait.pagesize,
        bTaskTypeCode: this.currentItem.TaskTypeID,
        batchID: this.currentItem.BatchID,
        supplierUserID: this.supplierUserID,
        paymentCode: this.paymentCode,
      }
      GetBillDetail(params)
        .then((res) => {
          this.waitBillList = res.data.DataList
          this.paginationWait.total = Number(res.data.TotalCount)
        })
        .finally(() => {
          this.flag.waitbillLoading = false
        })
    },
    searchWaitBillList() {
      this.paginationWait.page = 1
      this.getBillList()
    },
    // 导入轨迹权限
    getImportTraceAuth() {
      GetImportTraceAuth().then((res) => {
        this.flag.importTraceAuth = res.data
      })
    },
    //分页控件页码change事件回调
    handleCurrentChangeWait(e) {
      this.paginationWait.page = e
      this.getBillList()
    },
    // 点击立即付款
    getPayment() {
      this.flag.getPayDialog = true
      this.paymentInfo.supplierUserName = this.supplierList.filter(
        (it) => it.UserID == this.supplierUserID
      )[0].EnterpriseFullName
      this.paymentInfo.invoiceKindName = this.invoiceKindValue
      this.paymentInfo.paymentCodeName = this.paymentTypeList.filter(
        (it) => it.Code == this.paymentCode
      )[0].Name
      this.flag.showOfflineVoucher =
        this.paymentInfo.paymentCodeName.includes('线下')
      this.setInvoiceTaskID(this.currentItem.TaskTypeID)
      getYMAccount({
        userid: '',
      }).then((res) => {
        if (res.data) {
          this.accountInfo = res.data || {}
        }
      })
    },
    // 确认付款按钮是否可选
    showComfirmPay() {
      // 设置 确认付款 按钮是否可以选中
      // setTimeout(() => {
      //     this.flag.isComfirmPay =
      //         this.$refs.uploadFile.currentVoucherList.length > 0 ? false : true;
      // }, 100);
    },
    //确认付款
    comfirmPays() {
      this.$confirm('您是否确定进行付款？', '确认付款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true,
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '付款中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })
          this.flag.getPayLoading = true
          PayAndCreateStatement({
            bTaskTypeCode: this.currentItem.TaskTypeID,
            batchID: this.currentItem.BatchID,
            supplierUserID: this.supplierUserID,
            invoiceKind: this.invoiceKindVal1.Code,
            invoiceKindTec: this.invoiceKindVal2.Code,
            payModeID: this.paymentCode,
          })
            .then((res) => {
              this.$router.push('/purchaseManage/index/handlePurchaseOrder')
              this.$message.success('付款成功')
            })
            .finally(() => {
              this.flag.getPayLoading = false
              loading.close()
            })
        })
        .catch(() => {})
    },
    // 全部运单下载表格
    downloadBillList() {
      const loading = this.$loading({
        lock: true,
        text: '下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      DownloadBillList(
        {
          IsWaitBill: false,
          Status: this.billStatus,
          batchID: this.currentItem.BatchID,
          goodsName: this.goodsName,
          pageIndex: 0,
          pageSize: 0,
        },
        {
          responseType: 'blob',
        }
      ).catch((err) => {
        let { data } = err
        let that = this
        if (err.type == 'application/json') {
          let reader = new FileReader()
          reader.readAsText(err)
          reader.onload = function (e) {
            const { msg } = JSON.parse(reader.result)
            that.$message({
              message: `下载失败 ${msg}`,
              type: 'error',
            })
          }
          return
        }

        const blob = new Blob([data])
        let fileName = err.headers['content-disposition']
          .split(';')[1]
          .split('=')[1] //拆解获取文件名，
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
        loading.close()
      })
    },
    // 获取在线编辑运单列表
    async getEditList(type) {
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.flag.dialogLoading = true
      WaitBillList({
        Status: this.billStatus,
        BatchID: this.currentItem.BatchID,
        ...this.editBillManageForm,
        IsWaitBill: false,
      })
        .then((res) => {
          if (!res.data.length && type != 'search') {
            this.$message.warning('暂无错误运单可以编辑')
            return
          }
          this.flag.editWaybillDialog = true
          this.editWaybillList = res.data || []
          this.paginationEdit.total = this.editWaybillList.length
          //处理成二维数组
          let arrLength = parseInt(
            this.paginationEdit.total / this.paginationEdit.pagesize + 1
          )
          for (let i = 0; i < arrLength; i++) {
            this.currentEditWaybillSelectedList[i] = []
          }
        })
        .finally(() => {
          this.flag.dialogLoading = false
          loading.close()
        })
    },
    clearEditAllBill() {
      this.editBillManageForm = {
        shipownerPhone: '', // 承运人手机号
        shipName: '', // 船舶名称
        thirdBillID: '', // 客户单号
      }
      this.getEditList('search')
    },
    //处理编辑的表格选中变化
    currentEditWaybillSelectedChange({ records }) {
      this.currentEditWaybillSelectedList[this.paginationEdit.page - 1] =
        records
    },
    cellClassName({ row, rowIndex, column, columnIndex }) {
      if (column.property == 'RiskRemark') {
        return 'vux-table-col-red'
      }
    },
    headerCellClassName({ column, columnIndex }) {
      if (column.property == 'RiskRemark') {
        return 'vux-table-col-red'
      }
    },
    handleCurrentChangeEdit(e) {
      this.paginationEdit.page = e
    },
    //保存在线编辑的运单
    saveWaybill() {
      let params = []
      this.editWaybillList.map((item) => {
        params.push(item)
      })
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      UpdateBillDetail(params)
        .then((res) => {
          this.openAllBill(this.currentItem, 'RiskFail')
          this.flag.editWaybillDialog = false
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
    },
    //撤销编辑
    revertWaybill() {
      this.$refs.editWaybillRef.revertData()
    },
  },
  created() {
    // 获取导入轨迹权限
    this.getImportTraceAuth()
    // 查询单次最大上传数量
    GetSysValueAsync({
      keyType: 'WayBill',
      keyName: 'ImportQuantityLimit',
    }).then((res) => {
      this.maxNum = res.data.KeyValue
    })
    this.isSubmitApply = this.$route.query.isSubmitApply
      ? this.$route.query.isSubmitApply
      : false
    if (this.$route.query.bTaskTypeCode) {
      this.currentItem.TaskTypeID = this.$route.query.bTaskTypeCode
    }
    if (this.$route.query.BatchID) {
      this.batchID = this.$route.query.BatchID
      this.currentItem.BatchID = this.$route.query.BatchID
    }
    if (this.isSubmitApply) {
      this.submitApplyRequest()
    } else {
      this.getBTaskType(1, '')
    }
  },
  computed: {
    isAllSelect() {
      return this.supplierUserID && this.paymentCode && this.invoiceKindValue
    },
    currentEditWaybillList() {
      //编辑数据
      this.$nextTick(() => {
        if (
          this.currentEditWaybillSelectedList.length != 0 &&
          this.currentEditWaybillSelectedList[this.paginationEdit.page - 1]
            .length != 0
        ) {
          this.currentEditWaybillSelectedList[
            this.paginationEdit.page - 1
          ].forEach((item) => {
            this.editWaybillList.forEach((el) => {
              if (el.BBID === item.BBID) {
                this.$refs.editWaybillRef.setCheckboxRow(el, true)
              }
            })
          })
        }
      })
      return this.editWaybillList.slice(
        this.paginationEdit.pagesize * (this.paginationEdit.page - 1),
        this.paginationEdit.pagesize * this.paginationEdit.page
      )
    },
    //专票普票
    invoiceKind() {
      let invoiceKind = ''
      this.invoiceKindLists.list.forEach((item) => {
        if (item.Code == this.invoiceKindVal1.Code) {
          invoiceKind = item.Name
        }
      })
      return invoiceKind
    },
    invoiceKindTec() {
      let invoiceKindTec = ''
      this.invoiceKindLists.list1.forEach((item) => {
        if (item.Code == this.invoiceKindVal2.Code) {
          invoiceKindTec = item.Name
        }
      })
      return invoiceKindTec
    },
  },
  watch: {
    isAllSelect: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.getBillStatistics()
        } else {
          this.goodsInfo = {}
        }
      },
      immediate: true,
    },
    'flag.editWaybillDialog'(val) {
      if (!val) {
        this.paginationEdit.page = 1
      }
    },
  },
  components: {
    EvidenceInfo,
    TXmap,
  },
}
</script>
<style lang="scss">
.selectPurchaseScene {
  .settleBox {
    margin: 10px 0px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }

  .el-divider--horizontal {
    margin: 10px 0px;
  }

  .riskVerify {
    height: calc(100vh - 150px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .desc {
      font-size: 30px;
      font-weight: 700;
      margin-top: 30px;
    }
  }
}

.pay-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .info-con {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .title {
      width: 130px;
      font-size: 14px;
      color: #333333;
      font-weight: bold;
      text-align: right;
    }

    .con {
      font-size: 14px;
      color: #333333;
      width: 300px;
    }

    .con1 {
      font-size: 14px;
      font-family: Arial-Bold, Arial;
      font-weight: bold;
      color: #ff8b17;
    }
  }
}

.el-dialog__body {
  padding-top: 10px;
}

.vxe-table--tooltip-wrapper {
  z-index: 999999 !important;
}

.elx-table--tooltip-wrapper {
  z-index: 999999 !important;
}

.invoiceKindDialog {
  .el-dialog__header {
    padding-bottom: 0px;
  }

  .el-dialog__body {
    padding: 0px 20px;

    .el-divider--horizontal {
      margin: 16px 0px;
    }
  }

  .el-dialog__footer {
    padding-top: 16px;
    text-align: center;
  }
}
</style>
