import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 批次列表
export const ImportList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/ImportList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除批次
export const DelImport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/DelImport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 合并批次
export const MergeImport = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/MergeImport`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载导入模板
export const DownTemplate = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v3/BillLibrary/DownTemplate?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 导入业务单
export const UpLoadExcel = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/UpLoadExcel`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 修改批次备注
export const UpdateImportRemark = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/UpdateImportRemark`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 业务单列表
export const BillList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/BillList`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 下载业务单明细
export const DownloadBillList = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/DownloadBillList`,params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}


// 导入轨迹
export const ImportTrace = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v3/BillLibrary/ImportTrace`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}